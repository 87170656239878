<template>
  <v-container class="fullpage d-flex">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6">
        <h1 class="text-h2 text-lg-h1">{{ $t('blog.title') }}</h1><br>
        <p>{{ $t('blog.p[0]') }}</p>
        <p class="mb-7">{{ $t('blog.p[1]') }} <strong><i>{{ $t('blog.p[2]') }}</i></strong></p>
        <h2 class="text-h5 font-weight-bold mb-2">{{ $t('blog.p[3]') }}</h2>
        <h3 class="accent--text">{{ $t('blog.p[4]') }}</h3>
        <p>{{ $t('blog.p[5]') }}</p>
        <h3 class="accent--text">{{ $t('blog.p[6]') }}</h3>
        <p class="mb-7">{{ $t('blog.p[7]') }} 
          <v-btn color="primary" :href="$t('blog.href')" target="_blank" rounded text>{{ $t('blog.btn[0]') }}</v-btn></p>
        <v-btn color="primary" href="https://www.kontomourtos.gr/blog" target="_blank" rounded outlined>{{ $t('blog.btn[1]') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Blog'
}
</script>